import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import SidebarNav from './SidebarNav'
import logo from '../assets/placeholder-image.png'
import NewsSearch from './NewsSearch';
import LoginModal from './LoginModal';
import JoinNowModal from './JoinNowModal';
import { useSelector } from 'react-redux';
import SocialSearch from './socialSearch';
import LedgerSearch from './LedgerSearch';

function openNav() {
    document.getElementById("mySidebar").style.width = "250px";
    const overlay2 = document.getElementById("overlay2");
    overlay2.style.display = "block"; // Show the overlay
}
function Header() {
    const location = useLocation();
    const { user, userData, userProfileData } = useSelector((state) => state.user);
    const [loaded, setLoaded] = useState(true);
    
    // console.log("user: ", user, "Data", userData, "profile", userProfileData)
    const handleError = (e) => {
        setLoaded(false); // Fallback image
    }

    return (
        <div className={'header py-1 ' + (location.pathname === "/" ? "bg-transparent" : "shadowed") + (location.pathname === "/chat" ? " fixed-top" : "")}>
            {location.pathname === "/" ?
                <div className='header__left'>
                    <img className='bg-transparent round w-25' src={logo} alt="Profile Pic" />
                </div>
                :
                <div className='header__left'>
                    <a href="/" className='m-0'>
                        <p className='header__logoText fs-2 notranslate'>bluith</p>
                    </a>
                </div>

            }
            {/* will incude optional chaining for each search object based on location */}
            <div id="header" className={'header__right me-2 ' + (location.pathname === "/" ? "" : "")}>
            {(location.pathname === "/news" || location.pathname === "/news/") ? <NewsSearch /> : (location.pathname === "/social" || location.pathname === "/social/") ? <SocialSearch /> : (location.pathname === "/ledger" || location.pathname === "/ledger/") ? <LedgerSearch /> : <></>}
                {user
                    ?
                        <>
                            {userData?.profile_photo_url
                                ?
                                <img src={userData?.profile_photo_url} alt="Profile Pic" onClick={() => openNav()} onError={handleError} />
                                :
                                <p className='fs-5' onClick={() => openNav()}>{userData?.display_name?.slice(0, 1)}</p>
                            }
                        </>
                    :
                    <>
                        <JoinNowModal />
                        <LoginModal />
                    </>
                }
            </div>
            <SidebarNav />
        </div>
    )
}

export default Header